import __ from '@/helpers/localization.ts';

export function required(inputName: string, inputValue: any) {
  if (!inputValue) {
    return __('You have not entered ":resource"', {
      resource: __(inputName),
    });
  }
  return null;
}

export function required2(inputName: string, inputValue: any) {
  if (!inputValue) {
    return __('You have not chose ":resource"', {
      resource: __(inputName),
    });
  }
  return null;
}
