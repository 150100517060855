<template>
  <default-layout>
    <div class="ekyc default-layout-main">
      <div class="nicImageFront">
        <p class="mt-3 font-bold required">Mặt trước CMND</p>
        <span class="text-xs"
          >(Ảnh rõ ràng,không mất góc, không bị tối/ chói sáng)</span
        >
        <label for="upload-photo-front">
          <div
            class="image-ID border border-dashed border-tertiary bg-nic-front"
          >
            <img
              v-if="nicImageFront"
              :src="nicImageFront"
              class="preview-image"
              id="image-front"
            />
            <div class="absolute bottom-5 right-5 p-2 rounded-lg bg-white">
              <img src="@/assets/images/camera.png" for="upload-photo" />
              <form id="form-image-front">
                <input
                  type="file"
                  name="nicFront"
                  accept="image/*"
                  id="upload-photo-front"
                  @change="onFileChange"
                  class="hidden"
                  :disabled="uploadImaFrontSuccess"
                />
              </form>
            </div>

            <icon-check-circle
              v-if="uploadImaFrontSuccess"
              class="text-primary absolute right-0 top-0 rounded-full bg-white shadow-md w-10 h-10"
            ></icon-check-circle>
            <base-loading-app :loading="isUploadImageFront"></base-loading-app>
          </div>
        </label>
        <div class="text-center">
          <base-text-error :errorMessages="nicImageFrontErorr" />
        </div>
      </div>
      <form method="POST" @submit="handleSubmit" id="form-image">
        <div class="nicImageBack">
          <p class="mt-3 font-bold required">Mặt sau CMND</p>
          <span class="text-xs"
            >(Ảnh rõ ràng,không mất góc, không bị tối/ chói sáng)</span
          >
          <label for="upload-photo-back">
            <div
              class="image-ID border border-dashed border-tertiary bg-nic-back"
            >
              <img
                v-if="nicImageBack"
                :src="nicImageBack"
                class="preview-image"
                id="image-back"
              />
              <div class="absolute bottom-5 right-5 p-2 rounded-lg bg-white">
                <img src="@/assets/images/camera.png" for="upload-photo" />
                <input
                  type="file"
                  name="nicBack"
                  accept="image/*"
                  id="upload-photo-back"
                  @change="onFileChange"
                  class="hidden"
                />
              </div>
              <icon-check-circle
                v-if="nicImageBack"
                class="text-primary absolute right-0 top-0 rounded-full bg-white shadow-md w-10 h-10"
              ></icon-check-circle>
            </div>
          </label>
          <div class="text-center">
            <base-text-error :errorMessages="nicImageBackError" />
          </div>
        </div>
        <div class="selfieImage">
          <p class="mt-3 font-bold required">Ảnh khuôn mặt</p>
          <label for="upload-photo-selfieImage">
            <div
              class="image-ID border border-dashed border-tertiary selfie bg-selfie"
            >
              <img
                v-if="selfieImage"
                :src="selfieImage"
                class="preview-image"
                id="image-selfie"
              />
              <div class="absolute bottom-5 right-5 p-2 rounded-lg bg-white">
                <img src="@/assets/images/camera.png" for="upload-photo" />

                <input
                  type="file"
                  accept="image/*;capture=camera"
                  name="facePhoto"
                  capture
                  id="upload-photo-selfieImage"
                  @change="onFileChange"
                  class="hidden"
                />
              </div>
              <icon-check-circle
                v-if="selfieImage"
                class="text-primary absolute right-0 top-0 rounded-full bg-white shadow-md w-10 h-10"
              ></icon-check-circle>
            </div>
          </label>
          <div class="text-center">
            <base-text-error :errorMessages="selfieImageError" />
          </div>
        </div>
      </form>
      <base-button
        :loading="isLoading"
        :disabled="isUploadImageFront"
        @click="handleSubmit"
        class="w-full border-16 text-white my-10 py-6"
        :class="{
          'bg-secondary': isUploadImageFront,
          'bg-tertiary': !isUploadImageFront,
        }"
        >Tiếp tục
      </base-button>
    </div>
  </default-layout>
</template>

<script lang="ts">
import { ref } from 'vue';
import { defineComponent } from 'vue';
import DefaultLayout from '@/layouts/DefaultLayout.vue';
import BaseTextError from '@/components/Base/BaseTextError.vue';
import BaseButton from '@/components/Base/BaseButton.vue';
import constRouter from '@/constants/constRouter';
import { uploadImage, uploadFrontImage } from '@/apis/ApiEkyc';
import { mapMutations, mapGetters } from 'vuex';
import { required } from '@/helpers/validate';
import IconCheckCircle from '@/components/Icon/IconCheckCircle.vue';
import BaseLoadingApp from '@/components/Base/BaseLoadingApp.vue';

export default defineComponent({
  name: 'Ekyc',
  components: {
    BaseButton,
    DefaultLayout,
    BaseTextError,
    IconCheckCircle,
    BaseLoadingApp,
  },
  setup() {
    const nicImageFront = ref('');
    const nicImageBack = ref('');
    const selfieImage = ref('');
    const nicImageFrontErorr = ref(''); //image front rong
    const nicImageBackError = ref(''); //image back rong
    const selfieImageError = ref(''); //image selfie rong
    const uploadImaFrontSuccess = ref(false); //upload front len server thanh cong
    const isLoading = ref(false);
    const isUploadImageFront = ref(false); //dang upload anh nic front
    const serverError = ref(false);
    const countSubmit = ref(0);
    return {
      nicImageBack,
      nicImageFront,
      selfieImage,
      uploadImaFrontSuccess,
      nicImageFrontErorr,
      nicImageBackError,
      selfieImageError,
      isLoading,
      isUploadImageFront,
      serverError,
      countSubmit,
    };
  },
  computed: {
    ...mapGetters({
      getEkyc: 'getEkyc',
    }),
  },
  methods: {
    ...mapMutations({
      setEkyc: 'setEkyc',
      setFaceMatch: 'setFaceMatch',
    }),
    validation() {
      if (!this.nicImageFront) {
        this.nicImageFrontErorr = required('NicFront', this.nicImageFront);
      }
      this.nicImageBackError = required('NicBack', this.nicImageBack);
      this.selfieImageError = required('Selfie', this.selfieImage);
      if (!this.nicImageFront || !this.nicImageBack || !this.selfieImage) {
        return false;
      }
      return true;
    },
    rolateImage(img: any, name: string) {
      img.onload = () => {
        const width = img.width;
        const height = img.height;
        if (height / width > 1.3) {
          document.getElementById(name)?.classList.add('rolate-img');
        } else {
          document.getElementById(name)?.classList.remove('rolate-img');
        }
      };
    },
    onUploadSelfie(img: any) {
      img.onload = () => {
        const width = img.width;
        document.getElementById('image-selfie')?.classList.add('upload-selfie');
        const selfieEle: any = document.getElementsByClassName(
          'upload-selfie',
        )[0];
        selfieEle.style.width = `${width}px`;
        selfieEle.style.transform = `translate(-50%,-50%)`;
      };
    },
    onFileChange(e: any) {
      const name = e.target.name;
      const file = e.target.files[0];
      const img = new Image();
      img.src = URL.createObjectURL(file);
      if (name === 'nicFront') {
        this.isUploadImageFront = true;
        //trang thai upload img front
        this.uploadImaFrontSuccess = false;
        //messeage loi
        this.nicImageFrontErorr = '';
        //img front
        this.nicImageFront = URL.createObjectURL(file);
        const form: any = document.getElementById('form-image-front');
        const formdata = new FormData(form);
        // this.rolateImage(img, 'image-front');
        uploadFrontImage(formdata)
          .then(res => {
            const {
              data: {
                data: { info },
              },
            } = res;
            if (info) {
              this.setEkyc({
                ocrId: info.ocrId,
                nicNumber: info.nicNumber,
                fullName: info.fullName,
                birthday: info.birthday,
                address: info.address,
                gender: info.gender,
                cardType: info.cardType,
                nicExpired: info.nicNumberOld,
              });
            }
            this.isLoading = false;
            this.isUploadImageFront = false;
            this.uploadImaFrontSuccess = true;
          })
          .catch(err => {
            console.log(err);
            this.isUploadImageFront = false;
            this.uploadImaFrontSuccess = true;
            // if (err.data?.statusCode === 429) {
            //   this.nicImageFrontErorr = `Upload ảnh không thành công, vui lòng thử lại sau 1 phút`;
            // } else if (err.data?.statusCode === 422) {
            //   this.nicImageFrontErorr = err.data.message;
            // } else {
            //   this.nicImageFrontErorr = 'Ảnh không hợp lệ, vui lòng thử lại ';
            // }
          });
      }
      if (name === 'nicBack') {
        // this.rolateImage(img, 'image-back');
        this.nicImageBack = URL.createObjectURL(file);
      }
      if (name === 'facePhoto') {
        this.onUploadSelfie(img);
        this.selfieImage = URL.createObjectURL(file);
      }
    },
    handleSubmit() {
      if (this.validation()) {
        const ocrId = this.getEkyc.ocrId;
        const form: any = document.getElementById('form-image');
        const formdata = new FormData(form);
        formdata.append('orcId', ocrId);
        // if (this.uploadImaFrontSuccess) {
        this.isLoading = true;
        uploadImage(formdata)
          .then(res => {
            const { faceMatchResult } = res.data.data;
            if (faceMatchResult) {
              this.setFaceMatch({
                statusMatch: faceMatchResult.isMatch,
                per: faceMatchResult.percent,
              });
            }
            this.isLoading = false;
            // this.$router.push({
            //   name: constRouter.EKYC_INTRO_CONFIRM.name,
            // });
            this.$router.push({ name: constRouter.DASHBOARD.name });
          })
          .catch(err => {
            console.log(err);
            this.isLoading = false;
            this.$router.push({ name: constRouter.DASHBOARD.name });
          });
        // } else {
        //   const el = document.querySelector('.default-layout');
        //   if (el) {
        //     el.scrollTop = 0;
        //   }
        //   this.countSubmit += 1;
        //   if (this.countSubmit === 3) {
        //     uploadImage(formdata)
        //       .then(res => {
        //         const { faceMatchResult } = res.data.data;
        //         if (faceMatchResult) {
        //           this.setFaceMatch({
        //             statusMatch: faceMatchResult.isMatch,
        //             per: faceMatchResult.percent,
        //           });
        //         }
        //         this.isLoading = false;
        //       })
        //       .catch(err => {
        //         console.log(err);
        //         this.isLoading = false;
        //       });
        //     this.$router.push({
        //       name: constRouter.EKYC_INTRO_CONFIRM.name,
        //     });
        //   }
        // }
      } else {
        const el = document.querySelector('.default-layout');
        if (el) {
          el.scrollTop = 0;
        }
      }
    },
  },
});
</script>

<style lang="scss" scoped>
.required::after {
  margin-left: 2px;
  content: '*';
  color: red;
}
.ekyc {
  .nicImageFront,
  .nicImageBack,
  .selfieImage {
    .image-ID {
      border-radius: 15px;
      position: relative;
      text-align: center;
      height: 200px;
      max-width: 300px;
      margin: auto;
      overflow: hidden;
      background-size: 100% 100%;

      &.selfie {
        max-height: 300px;
        height: 300px;

        .upload-selfie {
          position: absolute;
          top: 50%;
          left: 50%;
          height: auto;
        }
      }

      .rolate-img {
        height: 320px !important;
        width: 200px;
        transform: rotate(-90deg) translateX(30%) translateY(15%);
      }
    }
  }
}

@media only screen and (max-width: 320px) {
  .ekyc {
    .nicImageFront,
    .nicImageBack {
      .image-ID {
        .rolate-img {
          height: 265px !important;
          transform: rotate(-90deg) translateX(20%) translateY(12%);
        }
      }
    }
  }
}
#image-front,
#image-back {
  width: 300px;
  height: 200px;
  object-fit: cover;
  object-position: center;
}
</style>
