
import { ref } from 'vue';
import { defineComponent } from 'vue';
import DefaultLayout from '@/layouts/DefaultLayout.vue';
import BaseTextError from '@/components/Base/BaseTextError.vue';
import BaseButton from '@/components/Base/BaseButton.vue';
import constRouter from '@/constants/constRouter';
import { uploadImage, uploadFrontImage } from '@/apis/ApiEkyc';
import { mapMutations, mapGetters } from 'vuex';
import { required } from '@/helpers/validate';
import IconCheckCircle from '@/components/Icon/IconCheckCircle.vue';
import BaseLoadingApp from '@/components/Base/BaseLoadingApp.vue';

export default defineComponent({
  name: 'Ekyc',
  components: {
    BaseButton,
    DefaultLayout,
    BaseTextError,
    IconCheckCircle,
    BaseLoadingApp,
  },
  setup() {
    const nicImageFront = ref('');
    const nicImageBack = ref('');
    const selfieImage = ref('');
    const nicImageFrontErorr = ref(''); //image front rong
    const nicImageBackError = ref(''); //image back rong
    const selfieImageError = ref(''); //image selfie rong
    const uploadImaFrontSuccess = ref(false); //upload front len server thanh cong
    const isLoading = ref(false);
    const isUploadImageFront = ref(false); //dang upload anh nic front
    const serverError = ref(false);
    const countSubmit = ref(0);
    return {
      nicImageBack,
      nicImageFront,
      selfieImage,
      uploadImaFrontSuccess,
      nicImageFrontErorr,
      nicImageBackError,
      selfieImageError,
      isLoading,
      isUploadImageFront,
      serverError,
      countSubmit,
    };
  },
  computed: {
    ...mapGetters({
      getEkyc: 'getEkyc',
    }),
  },
  methods: {
    ...mapMutations({
      setEkyc: 'setEkyc',
      setFaceMatch: 'setFaceMatch',
    }),
    validation() {
      if (!this.nicImageFront) {
        this.nicImageFrontErorr = required('NicFront', this.nicImageFront);
      }
      this.nicImageBackError = required('NicBack', this.nicImageBack);
      this.selfieImageError = required('Selfie', this.selfieImage);
      if (!this.nicImageFront || !this.nicImageBack || !this.selfieImage) {
        return false;
      }
      return true;
    },
    rolateImage(img: any, name: string) {
      img.onload = () => {
        const width = img.width;
        const height = img.height;
        if (height / width > 1.3) {
          document.getElementById(name)?.classList.add('rolate-img');
        } else {
          document.getElementById(name)?.classList.remove('rolate-img');
        }
      };
    },
    onUploadSelfie(img: any) {
      img.onload = () => {
        const width = img.width;
        document.getElementById('image-selfie')?.classList.add('upload-selfie');
        const selfieEle: any = document.getElementsByClassName(
          'upload-selfie',
        )[0];
        selfieEle.style.width = `${width}px`;
        selfieEle.style.transform = `translate(-50%,-50%)`;
      };
    },
    onFileChange(e: any) {
      const name = e.target.name;
      const file = e.target.files[0];
      const img = new Image();
      img.src = URL.createObjectURL(file);
      if (name === 'nicFront') {
        this.isUploadImageFront = true;
        //trang thai upload img front
        this.uploadImaFrontSuccess = false;
        //messeage loi
        this.nicImageFrontErorr = '';
        //img front
        this.nicImageFront = URL.createObjectURL(file);
        const form: any = document.getElementById('form-image-front');
        const formdata = new FormData(form);
        // this.rolateImage(img, 'image-front');
        uploadFrontImage(formdata)
          .then(res => {
            const {
              data: {
                data: { info },
              },
            } = res;
            if (info) {
              this.setEkyc({
                ocrId: info.ocrId,
                nicNumber: info.nicNumber,
                fullName: info.fullName,
                birthday: info.birthday,
                address: info.address,
                gender: info.gender,
                cardType: info.cardType,
                nicExpired: info.nicNumberOld,
              });
            }
            this.isLoading = false;
            this.isUploadImageFront = false;
            this.uploadImaFrontSuccess = true;
          })
          .catch(err => {
            console.log(err);
            this.isUploadImageFront = false;
            this.uploadImaFrontSuccess = true;
            // if (err.data?.statusCode === 429) {
            //   this.nicImageFrontErorr = `Upload ảnh không thành công, vui lòng thử lại sau 1 phút`;
            // } else if (err.data?.statusCode === 422) {
            //   this.nicImageFrontErorr = err.data.message;
            // } else {
            //   this.nicImageFrontErorr = 'Ảnh không hợp lệ, vui lòng thử lại ';
            // }
          });
      }
      if (name === 'nicBack') {
        // this.rolateImage(img, 'image-back');
        this.nicImageBack = URL.createObjectURL(file);
      }
      if (name === 'facePhoto') {
        this.onUploadSelfie(img);
        this.selfieImage = URL.createObjectURL(file);
      }
    },
    handleSubmit() {
      if (this.validation()) {
        const ocrId = this.getEkyc.ocrId;
        const form: any = document.getElementById('form-image');
        const formdata = new FormData(form);
        formdata.append('orcId', ocrId);
        // if (this.uploadImaFrontSuccess) {
        this.isLoading = true;
        uploadImage(formdata)
          .then(res => {
            const { faceMatchResult } = res.data.data;
            if (faceMatchResult) {
              this.setFaceMatch({
                statusMatch: faceMatchResult.isMatch,
                per: faceMatchResult.percent,
              });
            }
            this.isLoading = false;
            // this.$router.push({
            //   name: constRouter.EKYC_INTRO_CONFIRM.name,
            // });
            this.$router.push({ name: constRouter.DASHBOARD.name });
          })
          .catch(err => {
            console.log(err);
            this.isLoading = false;
            this.$router.push({ name: constRouter.DASHBOARD.name });
          });
        // } else {
        //   const el = document.querySelector('.default-layout');
        //   if (el) {
        //     el.scrollTop = 0;
        //   }
        //   this.countSubmit += 1;
        //   if (this.countSubmit === 3) {
        //     uploadImage(formdata)
        //       .then(res => {
        //         const { faceMatchResult } = res.data.data;
        //         if (faceMatchResult) {
        //           this.setFaceMatch({
        //             statusMatch: faceMatchResult.isMatch,
        //             per: faceMatchResult.percent,
        //           });
        //         }
        //         this.isLoading = false;
        //       })
        //       .catch(err => {
        //         console.log(err);
        //         this.isLoading = false;
        //       });
        //     this.$router.push({
        //       name: constRouter.EKYC_INTRO_CONFIRM.name,
        //     });
        //   }
        // }
      } else {
        const el = document.querySelector('.default-layout');
        if (el) {
          el.scrollTop = 0;
        }
      }
    },
  },
});
