import Axios from '../services/axios';

const PATH = 'customers';
export async function uploadFrontImage(formdata: object) {
  return await Axios.post(`${PATH}/nic-front`, formdata);
}

export async function uploadImage(formdata: object) {
  return await Axios.post(`${PATH}/nic-back-face`, formdata);
}

export function confirmData(data: any) {
  return Axios.post(`${PATH}/ocr-confirm`, data);
}

export async function uploadHouseHold(formdata: object) {
  return await Axios.post(`${PATH}/house-hold-image`, formdata);
}
