<template>
  <button
    :disabled="disabled"
    class="h-9 shadow-md hover:opacity-90 base-button inline-block text-xs font-medium leading-6 text-center uppercase transition rounded ripple focus:outline-none relative"
    :class="{
      'loading text-app': loading,
    }"
  >
    <span
      v-if="loading"
      class="absolute top-0 left-0 w-full h-full flex items-center justify-center"
    >
      <base-loading />
    </span>
    <span
      class="whitespace-nowrap"
      :class="{
        'opacity-0': loading,
      }"
    >
      <slot />
    </span>
  </button>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import BaseLoading from '@/components/Base/BaseLoading.vue';

export default defineComponent({
  name: 'BaseButton',
  components: { BaseLoading },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  methods: {},
});
</script>

<style scoped lang="scss">
.base-button {
  font-weight: 500;
  letter-spacing: 0;
  font-size: 17px;
  user-select: none;
  display: inline-flex;
  align-items: center;
  line-height: 1;
  justify-content: center;

  &.border-1 {
    border: 1px solid;
  }

  &.border-16 {
    border-radius: 16px;
  }

  &.large {
    height: 52px;
  }

  &.small {
    height: 28px;
    font-size: 12px;
  }

  &.loading {
    pointer-events: none;
    background: #f5f5f5 !important;
    box-shadow: unset !important;
    border: none;
  }
}
</style>
